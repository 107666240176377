import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SingleRaisedFloorQuery from "../graphql/SingleRaisedFloorQuery"
import PageHeader from "./PageHeader"
import Breadcrumbs from "../components/Breadcrumbs"
import FloorProductIntroduction from "../components/FloorProductIntroduction"
import SingleProductQuery from "../graphql/SingleProductQuery"
import PageTitle from "../components/PageTitle"
import { Desc } from "../graphql/SidingSmallQueries"
import SEO from '../components/seo'

const FloorSinglePageTemplate = ({ data: { airtable } }) => {
  const mainName = Desc()[0].name
  // console.log(airtable)
  const isSiding = airtable.table === "Siding"
  // console.log(isSiding)
  // const data = airtable?.data ?? undefined
  // const path = data ?? undefined
  // const floor = SingleRaisedFloorQuery(path)
  const siding = SingleProductQuery(airtable)

  return (
    <Layout>
      <SEO title={siding.name} />
      {/* <PageHeader title={siding.name} /> */}
      <PageTitle title={siding.name} />
      <div className="relative z-10 max-w-screen-lg mx-auto my-10 md:shadow-xl md:p-10 md:bg-white">
        <Breadcrumbs
          // productType={siding.productType}
          productType={mainName}
          productName={siding.name}
          nameEn="siding"
        />
        <FloorProductIntroduction data={siding} single isSiding={isSiding} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query GetSingleSiding($id: String!) {
    airtable(id: { eq: $id }) {
      data {
        nameMn: Name_mongol
        nameEn: Name_english
        Ontslog {
          data {
            nameMn: mn
            nameEn: en
            Icon {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    width: 100
                    placeholder: NONE
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
        Type: Siding_Type {
          data {
            nameMn: Name_mongol
            nameEn: Name_english
            textMn: Mongol_tailbar
            textEn: English_tailbar
            Image {
              localFiles {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
              }
            }
            Icon {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    width: 100
                    placeholder: NONE
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
        size: Siding_Size
        mdMn: Description_mongol {
          internal {
            content
          }
        }
        mdEn: Description_english {
          internal {
            content
          }
        }
        New
        ontsloh: Ontsloh_baraa_bolgoh
        Image {
          localFiles {
            childImageSharp {
              gatsbyImageData(
                width: 400
                layout: CONSTRAINED
                # placeholder: BLURRED
              )
            }
          }
        }
        country: Made_In {
          data {
            mn
            en
            Flag {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    width: 100
                    layout: CONSTRAINED
                    placeholder: NONE
                  )
                }
              }
            }
          }
        }
        Brand {
          data {
            mn
            en
            Logo {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    width: 200
                    layout: CONSTRAINED
                    placeholder: NONE
                  )
                }
              }
            }
          }
        }
        Color {
          data {
            hex: Hex_Color
            nameMn: mn
            nameEn: en
            genColor: General_color
          }
        }
      }
      id
      table
    }
  }
`

export default FloorSinglePageTemplate
